<template>
  <div class="tool-list-wrapper">
    <div class="tool-list">
      <nuxt-link class="tool-item" v-for="tool in Home.toolList" :key="tool.name" :to="tool.path">
        <div class="img-wrapper">
          <img class="tool-icon" :src="tool.icon" />
        </div>
        <div>
          <div class="tool-title">
            <div>{{ $t(tool.name) }}</div>
            <img :src="arrowToolIcon" class="arrow-tool-right" />
          </div>
          <div class="tool-desc">{{ $t(tool.desc) }}</div>
        </div>
      </nuxt-link>
      <div class="tool-item-wrapper">
        <nuxt-link class="tool-item2" v-for="tool in Home.toolList2" :key="tool.name" :to="tool.path">
          <div class="img-wrapper">
            <img class="tool-icon" :src="tool.icon" />
          </div>
          <div>
            <div class="tool-title"><div>{{ $t(tool.name) }}</div><img :src="arrowToolIcon" class="arrow-tool-right" /></div>
            <div class="tool-desc">{{ $t(tool.desc) }}</div>
          </div>
        </nuxt-link>
      </div>
    </div>
    <!-- <div class="arrow-expend" @click="handlerArrowTop">
      <img :class="{ 'arrow-top': expend }" src="@/assets/icons/arrow-bottom-expand.svg" alt="arrow" />
    </div> -->
  </div>
  <div class="tool-list-wrapper-mobile">
    <div class="tool-list">
      <nuxt-link class="tool-item" v-for="tool in Home.toolList" :key="tool.name" :to="tool.path">
        <div class="tool-title">
          {{ $t(tool.name) }}
        </div>
        <img class="arrow-tool-right" src="@/assets/icons/arrow-tool-right.svg" />
        <div class="tool-desc">{{ $t(tool.desc) }}</div>
      </nuxt-link>
      <nuxt-link class="tool-item2 expend" v-for="tool in Home.toolList2" :key="tool.name"
        :to="tool.path">
        <div class="tool-title">
          {{ $t(tool.name) }}
        </div>
        <img class="arrow-tool-right" src="@/assets/icons/arrow-tool-right.svg" />
        <div class="tool-desc">{{ $t(tool.desc) }}</div>
      </nuxt-link>
    </div>
    <!-- <div class="more" v-if="!expend" @click="handlerArrowTop">
      <span>更多工具</span>
      <img src="@/assets/icons/arrow-down.svg" alt="arrow-down" />
    </div> -->
  </div>
</template>
<script lang="ts" setup>
import arrowToolIcon from '@/assets/icons/arrow-tool-right.svg';
import Home from '@/assets/contents/home.json';

const expend = ref(false);
const handlerArrowTop = () => {
  expend.value = !expend.value;
};
</script>
<style lang="less" scoped>
@media screen and(min-width:800px) {
  .tool-list-wrapper-mobile {
    display: none;
  }
  .tool-list-wrapper {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #f4faff 0%, #f0f8ff 0.01%, rgba(255, 255, 255, 0.38) 100%);

    .arrow-top {
      transform: rotate(180deg);
    }

    img {
      cursor: pointer;

      &:hover {
        border-radius: 20px;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
      }
    }
  }

  .tool-list {
    padding: 24px 0;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
    overflow-y: hidden;

    .tool-item-wrapper {
      display: flex;
      width: 100%;
      justify-content: center;
      gap: 15px;
    }

    .tool-item2,
    .tool-item {
      display: flex;
      align-items: center;
      min-width: 316px;
      height: 96px;
      cursor: pointer;
      transition: transform 0.5s;
      position: relative;
      text-decoration: none;
      border: 2px solid transparent;
      box-sizing: border-box;

      .tool-title {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 20px;
        line-height: 31px;
        color: #1d2129;
        box-sizing: border-box;
        div {
          max-width: 210px;
        }

        .arrow-tool-right {
          margin: 0 16px 0 4px;
          display: none;
        }
      }

      .tool-desc {
        margin-top: 7px;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #4e5969;
        box-sizing: border-box;
      }

      .img-wrapper {
        margin: 0 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        padding: 7px;
        opacity: 0.8;
        background: #ffffff;
        border-radius: 8px;

        .tool-icon {
          width: 42px;
          height: 42px;
        }
      }

      &:hover {
        background: url('https://video.reta-inc.com/image/default/6D25D44D646244ACA632D78B92E9CE70-6-2.png') no-repeat;
        background-size: 100%;
        border-color: #fff;
        transform: translateY(-4px);
        border-radius: 4px;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);

        .tool-title {
          .arrow-tool-right {
            display: block;
          }
        }
      }
    }
  }

  .arrow-expend {
    margin-top: -10px;
  }
}
</style>
<style lang="less" scoped>
@media screen and(max-width:800px) {
  .tool-list-wrapper {
    display: none;
  }
  .tool-list-wrapper-mobile {
    padding: 0 20px;
  }
  .tool-list {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      margin-top: 10px;
      margin-bottom: 24px;

      .tool-item2,
      .tool-item {
        width: calc(50% - 6px);
        display: block;
        text-decoration: none;
        position: relative;
        background: #ffffff;
        border: 1px solid #f2f3f5;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
        backdrop-filter: blur(6px);
        border-radius: 4px;
        padding: 14px 12px;
        box-sizing: border-box;

        .tool-title {
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          color: #1d2129;
        }

        .tool-desc {
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;
          color: #4e5969;
          margin-top: 6px;
        }

        .arrow-tool-right {
          position: absolute;
          right: 8px;
          top: 14px;
          width: 24px;
          height: 24px;
        }
      }

      .tool-item2 {
        display: none;

        &.expend {
          display: block;
        }
      }
  }
  .more {
    padding: 0px 0 24px 0;
    display: flex;
    justify-content: center;
    font-size: 14px;
    line-height: 18px;
    color: #4e5969;

    img {
      width: 15px;
    }
  }
}
</style>
